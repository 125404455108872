<template>
  <div class="d-flex mb-2 go-back-container" style="gap: 1rem">
    <div>
      <b-button
        variant="primary"
        @click="to ? $router.push(to) : $router.go(-1)"
        :title="$t('actions.go-back')"
      >
        <feather-icon icon="ChevronLeftIcon" />
        <span class="sr-only">{{ $t("actions.go-back") }}</span>
      </b-button>
    </div>
    <div>
      <slot />
    </div>
    <div v-if="title" class="go-back-page-title">
      <h1 class="m-0">{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "go-back",
  props: ["to", "title"],
};
</script>

<style>
.go-back-page-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .go-back-container {
    flex-direction: column;
  }
}
</style>