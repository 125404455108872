<template>
  <div>
    <b-row class="mb-2">
      <b-col cs="6" sm="6" md="8" class="mr-auto">
        <go-back :to="{ name: 'identification-list' }"
                 :title="$t('actions.identify')"/>
      </b-col>
    </b-row>

    <b-card class="mx-auto" style="max-width: 500px">
      <div class="upload-button" :class="{'invalid-file': hasFileError}" tabindex="0" title="Add a photo" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <span class="plus-icon-title">
          {{ $t("common.actions.select_photo") }}
          <br>
          <span class="font-small-2">or drag&drop here</span>
        </span>
        <feather-icon
            class="plus-icon"
            v-if="!selectedPhoto"
            icon="PlusIcon"
            @click="$refs.file.click()"
        />


        <cropper
            class="cropper"
            image-restriction="stencil"
            :auto-zoom="true"
            :src="selectedPhoto"
            v-else
            @change="onCrop"
            :stencil-props="{
            handlers: {
              eastNorth: true,
              north: false,
              westNorth: true,
              west: false,
              westSouth: true,
              south: false,
              eastSouth: true,
              east: false,
            },
            movable: false,
            scalable: false,
            aspectRatio: 1,
          }"
        />

        <input
            type="file"
            ref="file"
            accept=".png,.jpeg,.jpg"
            style="display: none"
            @change="photoSelected"
        />
      </div>
      <b-button-group
          class="d-flex mt-2"
          style="gap: 2rem"
          v-if="selectedPhoto"
      >
        <b-button
            class="btn-icon rounded w-100"
            variant="danger"
            title="Clear new photo"
            @click="clearSelectedPhoto"
        >
          <feather-icon icon="XIcon"/>
          {{ $t("actions.clear") }}
        </b-button>
        <b-button
            class="btn-icon rounded w-100"
            variant="success"
            @click="uploadSelectedPhoto"
            :disabled="loading"
        >
          <template v-if="loading">
            <b-spinner small/>
            <span class="sr-only"> {{ $t("general.loading") }}</span>
          </template>
          <template v-else>
            <feather-icon icon="CheckIcon"/>
            {{ $t("actions.search") }}
          </template>
        </b-button>
      </b-button-group>

      <b-card-footer class="pb-0" v-if="hasFileError">
        <div class="d-flex invalid-feedback" v-for="fileError in fileErrors" :key="fileError">
          <feather-icon icon="InfoIcon" class="mr-1"/>
          {{ fileError }}
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {store} from "@/api/queries.api";
import {Cropper} from "vue-advanced-cropper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-advanced-cropper/dist/style.css";
import GoBack from "@/components/Common/GoBack";
import FileValidationMixins from "@core/mixins/FileValidationMixins";

export default {
  data() {
    return {
      selectedPhoto: null,
      file: null,
      loading: false,
      croppedFile: null
    };
  },
  components: {GoBack, Cropper},
  mixins: [FileValidationMixins],
  methods: {
    deletePhoto({id}) {
      this.photos = this.photos.filter((p) => p.id != id);
    },
    photoSelected(event) {
      this.fileErrors = []
      const file = event.target.files[0];
      if (file) {
        this.handleFile(file)
      }
    },
    handleFile(file) {
      if (!this.validateFile(file)) {
        this.file = file;
        this.selectedPhoto = URL.createObjectURL(file);
      }
    },
    clearSelectedPhoto() {
      this.file = null;
      this.selectedPhoto = null;
    },
    async uploadSelectedPhoto() {
      this.loading = true;
      const data = new FormData();
      data.append("photo", this.croppedFile);
      const {data: result, error} = await store(data);

      if (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.title || this.$t("common.errors.unpected"),
            icon: "XIcon",
            variant: "danger",
          },
        });
        this.loading = false;
        return;
      }

      this.clearSelectedPhoto();
      this.$router.push({
        name: "identification-show",
        params: {id: result.id},
      });
      this.photos = [result.data, ...this.photos];
    },
    onCrop({canvas}) {
      canvas.toBlob((file) => (this.croppedFile = file), this.file.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem 1rem;
}

.upload-button {
  width: 100% !important;
  aspect-ratio: 1/1;
}

.upload-button {
  aspect-ratio: 1/1;
  position: relative;
  background-color: #0003;
  overflow: clip;
  border-radius: 0.5rem;
  background-blend-mode: color-burn;
  transition: all ease 0.2s;

  .plus-icon {
    cursor: pointer;
    padding: 20%;
    opacity: 0.8;
    transition: opacity ease-in 0.2s, padding ease-in 0.2s;
  }

  .plus-icon,
  img {
    position: absolute;
    width: 100%;
    height: 100%;

    margin: auto;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #0004;
    transition: background-color ease 0.2s;

    .plus-icon {
      opacity: 1;
      padding: 18%;
      transition: opacity ease-in 0.2s, padding ease-in 0.2s;
    }
  }

  .button-group {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  img {
    object-fit: cover;
  }
}

.cropper {
  max-width: 100%;
  height: 100%;
  max-height: 600px;
  background: #000;
}
.plus-icon-title {
  position: absolute;
  bottom: 18%;
  width: 100%;
  text-align: center;
}

</style>