var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mr-auto",attrs:{"cs":"6","sm":"6","md":"8"}},[_c('go-back',{attrs:{"to":{ name: 'identification-list' },"title":_vm.$t('actions.identify')}})],1)],1),_c('b-card',{staticClass:"mx-auto",staticStyle:{"max-width":"500px"}},[_c('div',{staticClass:"upload-button",class:{'invalid-file': _vm.hasFileError},attrs:{"tabindex":"0","title":"Add a photo"},on:{"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[_c('span',{staticClass:"plus-icon-title"},[_vm._v(" "+_vm._s(_vm.$t("common.actions.select_photo"))+" "),_c('br'),_c('span',{staticClass:"font-small-2"},[_vm._v("or drag&drop here")])]),(!_vm.selectedPhoto)?_c('feather-icon',{staticClass:"plus-icon",attrs:{"icon":"PlusIcon"},on:{"click":function($event){return _vm.$refs.file.click()}}}):_c('cropper',{staticClass:"cropper",attrs:{"image-restriction":"stencil","auto-zoom":true,"src":_vm.selectedPhoto,"stencil-props":{
          handlers: {
            eastNorth: true,
            north: false,
            westNorth: true,
            west: false,
            westSouth: true,
            south: false,
            eastSouth: true,
            east: false,
          },
          movable: false,
          scalable: false,
          aspectRatio: 1,
        }},on:{"change":_vm.onCrop}}),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".png,.jpeg,.jpg"},on:{"change":_vm.photoSelected}})],1),(_vm.selectedPhoto)?_c('b-button-group',{staticClass:"d-flex mt-2",staticStyle:{"gap":"2rem"}},[_c('b-button',{staticClass:"btn-icon rounded w-100",attrs:{"variant":"danger","title":"Clear new photo"},on:{"click":_vm.clearSelectedPhoto}},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" "+_vm._s(_vm.$t("actions.clear"))+" ")],1),_c('b-button',{staticClass:"btn-icon rounded w-100",attrs:{"variant":"success","disabled":_vm.loading},on:{"click":_vm.uploadSelectedPhoto}},[(_vm.loading)?[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.$t("general.loading")))])]:[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" "+_vm._s(_vm.$t("actions.search"))+" ")]],2)],1):_vm._e(),(_vm.hasFileError)?_c('b-card-footer',{staticClass:"pb-0"},_vm._l((_vm.fileErrors),function(fileError){return _c('div',{key:fileError,staticClass:"d-flex invalid-feedback"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"InfoIcon"}}),_vm._v(" "+_vm._s(fileError)+" ")],1)}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }